import { Role, type UserRole, UserRoleType } from "@prisma/client";
import { assertAuthorized } from "./assert.utils.server";
import type { SerializeFrom } from "@remix-run/node";
import { isEmpty } from "lodash";

export const AdminRole = {
  [Role.GOD_MODE]: "God Mode",
  [Role.RVI_SUPPORT]: "Onboarding Support",
};

export const AdminPermission = {
  AccessAdminModule: "Access Admin Module",

  // Products
  ViewProducts: "View Products",
  CreateProducts: "Create Products",
  UpdateProducts: "Update Products",
  DeleteProducts: "Delete Products",
  EditFullProductListings: "Edit Full Product Listings",
  ViewProductSubscriptions: "View Product Subscriptions",
  UpdatePrimaryCategory: "Update Primary Category",

  // Vendors
  ViewVendors: "View Vendors",
  CreateVendors: "Create Vendors",
  UpdateVendors: "Update Vendors",
  DeleteVendors: "Delete Vendors",
  UploadVendors: "Upload Vendors",

  // Users
  ViewUsers: "View Users",
  CreateUsers: "Create Users",
  UpdateUsers: "Update Users",
  DeleteUsers: "Delete Users",
  UpdateBuyerRole: "Update Buyer Role",
  ResendUserVerificationEmail: "Resend Email Verification",
  ImpersonateUser: "Impersonate User",

  // Contracts
  AddContractsOnOnboarding: "Add Contract Manually when Onboarding is Enabled",
  ManageBundledLineItems: "Manage Bundled Line Items",

  // Zipline
  ViewZipline: "View Zipline",
  CreateZiplineDraftContracts: "Create Zipline Draft Contracts",
  UpdateZiplineDraftContracts: "Update Zipline Draft Contracts",
  DeleteZiplineDraftContracts: "Delete Zipline Draft Contracts",
  CreateZiplineContracts: "Create Zipline Contracts",
  UpdateZiplineContracts: "Update Zipline Contracts",
  DeleteZiplineContracts: "Delete Zipline Contracts",
  ViewZiplineContractContractLineItems: "View Zipline Contract Line Items",
  ViewZiplineContractDetails: "View Zipline Contract Details",
  ViewZiplineContractLocations: "View Zipline Contract Locations",
  ManageZiplineContractLineItem: "Manage Zipline Contract Line Item",
  ViewZiplineContractLineItemDetails: "View Zipline Contract Line Item Details",
  ManageZiplineContractLineItemLocations:
    "View Zipline Contract Line Item Locations",
  ApproveZiplineContracts: "Approve Zipline Contracts",
  AssignContractLineItemDepartment: "Assign Contract Line Item Department",
  ReAnalyzeZiplineContracts: "Re-Analyze Zipline Contracts",
  ViewActiveContracts: "View Active Contracts",

  // Categories
  ViewCategories: "View Categories",
  CreateCategories: "Create Categories",
  UpdateCategories: "Update Categories",
  DeleteCategories: "Delete Categories",

  // Reviews
  ViewReviews: "View Reviews",
  ApproveReviews: "Approve Reviews",
  SetReviewBadge: "Set Review Badge",
  DeleteReviews: "Delete Reviews",

  // Accounts
  ViewManagerAccounts: "View Manager Accounts",
  UpdateManagerAccounts: "Update Manager Accounts",
  CreateManagerAccounts: "Create Manager Accounts",

  // Utilities
  ViewUtilities: "View Utilities",
  SyncCirclePosts: "Sync Circle Posts",
  AddPostAliases: "Add Post Alias",
  SyncContractsAndSendRenewalReminders:
    "Sync Contracts and Send Renewal Reminders",
  SyncPropexoIntegrations: "Sync Propexo Integrations",
  ViewBullMQDashboard: "View BullMQ Dashboard",
  ManageGLImportConfig: "Manage GL Import Config",
  SyncRangeWaterSalesforceLocations: "Sync RangeWater Salesforce Locations",
  RemoveFilesCanceledContracts: "Remove Files from Canceled Contracts",

  // RVI
  GoIntoRVIAccounts: "Go into RVI Accounts",
  ViewRVITotals: "View RVI Totals",
  DeleteRVIDocuments: "Delete Documents On Documents Table",
  GenerateRVIDocumentAiSummary: "Generate RVI Document AI Summary",
} as const;

type PermissionKeys = keyof typeof AdminPermission;
export type AdminPermissionType = (typeof AdminPermission)[PermissionKeys];

export const adminRolePermissionMap: Record<
  keyof typeof AdminRole,
  Readonly<AdminPermissionType[]>
> = {
  [Role.GOD_MODE]: Object.values(AdminPermission),
  [Role.RVI_SUPPORT]: [
    AdminPermission.AccessAdminModule,

    // Products
    AdminPermission.ViewProducts,
    AdminPermission.CreateProducts,
    AdminPermission.UpdateProducts,
    AdminPermission.DeleteProducts,

    // Vendors
    AdminPermission.ViewVendors,
    AdminPermission.CreateVendors,
    AdminPermission.UpdateVendors,
    AdminPermission.DeleteVendors,
    AdminPermission.UploadVendors,

    // Zipline
    AdminPermission.ViewZipline,
    AdminPermission.CreateZiplineDraftContracts,
    AdminPermission.UpdateZiplineDraftContracts,
    AdminPermission.DeleteZiplineDraftContracts,
    AdminPermission.CreateZiplineContracts,
    AdminPermission.UpdateZiplineContracts,
    AdminPermission.DeleteZiplineContracts,
    AdminPermission.ViewZiplineContractContractLineItems,
    AdminPermission.ViewZiplineContractDetails,
    AdminPermission.ViewZiplineContractLocations,
    AdminPermission.ManageZiplineContractLineItem,
    AdminPermission.ViewZiplineContractLineItemDetails,
    AdminPermission.ManageZiplineContractLineItemLocations,

    AdminPermission.ViewRVITotals,
  ],
} as const;

export type AdminPermissionUser = {
  id?: string;
  email?: string;
  user_roles: UserRole[] | SerializeFrom<UserRole>[];
};

export function adminCanDo(
  user: AdminPermissionUser,
  permission: AdminPermissionType | AdminPermissionType[]
): boolean {
  if (!user) return false;

  const adminUserGlobalRoles = user.user_roles.filter(
    role =>
      role.type === UserRoleType.GLOBAL &&
      Object.keys(AdminRole).includes(role.role)
  );

  // If no admin role, return false
  if (isEmpty(adminUserGlobalRoles)) return false;

  const permissions = adminUserGlobalRoles.flatMap(
    role => adminRolePermissionMap[role.role as keyof typeof AdminRole]
  );
  return permission instanceof Array
    ? permission.every(p => permissions.includes(p))
    : permissions.includes(permission);
}

export function adminCanDoOrThrow(
  user: AdminPermissionUser,
  permission: AdminPermissionType | AdminPermissionType[]
): void {
  const authorized = adminCanDo(user, permission);

  if (authorized) return;

  const message = `You are not authorized to ${permission}`;

  assertAuthorized(authorized, message);
}

export function adminCanDoSome(
  user: AdminPermissionUser,
  permissions: AdminPermissionType[]
): boolean {
  return permissions.some(permission => adminCanDo(user, permission));
}

export function canDoSomeOnAccountOrThrow(
  user: AdminPermissionUser,
  permissions: AdminPermissionType[]
): void {
  const authorized = adminCanDoSome(user, permissions);

  if (authorized) return;

  const message = `You are not authorized to go into this admin section`;

  assertAuthorized(authorized, message);
}

export function hasAdminRole(user: AdminPermissionUser | null | undefined) {
  if (!user) return false;
  return user.user_roles.some(
    role =>
      role.type === UserRoleType.GLOBAL &&
      Object.keys(AdminRole).includes(role.role)
  );
}
